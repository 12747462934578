import React from "react";
import { useTheme, ThemeProvider } from "emotion-theming";
import { Global, css } from "@emotion/core";
import { Box } from "@graphite/uneon";

import "normalize.css";
import "../../css/typography.css";
import themes from "./libs/themes";

type TProps = { children: React.ReactNode };

const Wrapper: React.FC<TProps> = ({ children }: TProps) => {
	const { colors } = useTheme();

	return (
		<>
			<Global
				styles={css`
					body {
						background: ${colors.bg.primary};
						overflow-x: hidden;
					}
				`}
			/>
			{children}
		</>
	);
};

const Layout: React.FC<TProps> = ({ children }: TProps) => (
	<ThemeProvider theme={themes.dark}>
		<Box bg="bg.primaryminus">
			<Wrapper>{children}</Wrapper>
		</Box>
	</ThemeProvider>
);

export default Layout;
