import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Flex, Button } from "@graphite/uneon";
import { useStaticQuery, graphql } from "gatsby";

import Burger from "./Burger";

import LogoSvg from "../../../images/logo.inline.svg";
import CopySvg from "./images/copy.inline.svg";

const topbarSx = {
	position: "relative",
	justifyContent: "space-between",
	alignItems: "center",
	height: "42px",
	margin: ["18px 0", "36px 0", "36px 0", "36px 0"],
	width: "100%",
	zIndex: 9999,
};

const topbarButtonSx = {
	marginLeft: "12px",
	display: ["none", "flex"],
};

const logoSx = {
	"#letters": {
		fill: "text.primaryalt",
	},
	"#alpha": {
		fill: "text.accent",
	},
};
type UrlData = {
	site: {
		siteMetadata?: {
			appUrl: string;
		};
	};
};

const CopyIcon = styled(CopySvg)`
	transition: fill ease-out 0.25s;
	margin-left: 12px;
`;

const TopBar = () => {
	const { site }: UrlData = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						appUrl
					}
				}
			}
		`
	);

	const homeUrl = site.siteMetadata?.appUrl || "/";

	const { t } = useTranslation("translation", { useSuspense: false });

	return (
		<Flex sx={topbarSx}>
			<Flex as="a" href="/" sx={logoSx}>
				<LogoSvg />
			</Flex>

			<Flex justifyContent="left">
				<Button
					as="a"
					href="//feedback.graphite.space"
					target="_blank"
					rel="noreferrer"
					variant="primaryaltflat.md"
					sx={topbarButtonSx}
					mr={[0, 0, "30px"]}
				>
					{t("Help")}
					<CopyIcon />
				</Button>
				<Burger appUrl={homeUrl} />
				<Button
					as="a"
					href={`${homeUrl}login`}
					variant="primaryaltflat.md"
					sx={topbarButtonSx}
				>
					{t("topbar.login")}
				</Button>
				<Button
					as="a"
					href={`${homeUrl}signup`}
					variant="accentghost.rounded.md"
					sx={topbarButtonSx}
				>
					{t("topbar.signup")}
				</Button>
			</Flex>
		</Flex>
	);
};

export default React.memo(TopBar);
